import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { TextStyle, TextField, Button, InlineError } from '@shopify/polaris'
import { format, differenceInCalendarDays } from 'date-fns'
import { useFormik } from 'formik'
import DatePicker from 'react-datepicker'
import { cloneDeep } from 'lodash'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'

import { backendURL, isPastDate } from '../../utils'

const LAST_2_CHARS = /.{2}$/
const lowerCase = (value) => value.toLowerCase()
const formatDate = (date) => {
  return format(new Date(date), 'MMMM d, yyyy, hh:mma').replace(
    LAST_2_CHARS,
    lowerCase
  )
}

window.format = format
function InventoryLineItem({
  item,
  style,
  accessToken,
  onUpdate = () => {},
  minDate,
}) {
  const mounted = useRef(true)
  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  })
  const inventory = item._inventory

  const shouldUpdateAvailble =
    inventory.availableUpdatedAt !== null &&
    differenceInCalendarDays(
      new Date(),
      new Date(inventory.availableUpdatedAt)
    ) > 7

  let shouldUpdateIncoming = false

  if (inventory.incomingUpdatedAt) {
    shouldUpdateIncoming =
      differenceInCalendarDays(
        new Date(),
        new Date(inventory.incomingUpdatedAt)
      ) > 7 && inventory.available < inventory.minAvailableAlertThreshold
  } else {
    // never updated before
    shouldUpdateIncoming =
      inventory.available < inventory.minAvailableAlertThreshold
  }

  const formik = useFormik({
    initialValues: {
      incoming: inventory.incoming,
      available: inventory.available,
      incomingExpectedOn:
        inventory.incomingExpectedOn !== null
          ? new Date(inventory.incomingExpectedOn)
          : null,
    },
    validate: (values) => {
      const errors = {}

      if (
        typeof values.incoming === 'number' &&
        values.incoming > 0 &&
        values.incomingExpectedOn === null
      ) {
        errors.incomingExpectedOn = `Required`
      }

      if (values.incomingExpectedOn && isPastDate(values.incomingExpectedOn)) {
        errors.incomingExpectedOn = `Needs updating`
      }

      if (values.incomingExpectedOn && values.incoming === 0) {
        errors.incoming = `Required`
      }

      return errors
    },
    validateOnMount: false,
    enableReinitialize: true,
    // validate,
    onSubmit: (values, { setSubmitting, setStatus, resetForm }) => {
      setSubmitting(true)
      setStatus(null)

      const body = cloneDeep(item)
      body._inventory.available = values.available
      body._inventory.incoming = values.incoming
      body._inventory.incomingExpectedOn = values.incomingExpectedOn
        ? values.incomingExpectedOn.toISOString()
        : values.incomingExpectedOn

      axios
        .post(`${backendURL}/api/inventory-item`, body, {
          headers: { authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          setSubmitting(false)
          setStatus(null)
          const updatedItem = response.data.data
          formik.setTouched(false)
          onUpdate(updatedItem)
          mounted.current && resetForm()
          return updatedItem
        })
        .catch((err) => {
          console.error(err.message)
          console.error(err.response)
          setSubmitting(false)
          setStatus(
            'Unexpected error occured. Please try again or report the error to the admin.'
          )
        })
    },
  })

  return (
    <tr
      style={style}
      className={cx({
        'error-incoming-expected': formik.errors.incomingExpectedOn,
        'tall-row': Object.keys(formik.errors).length > 0,
      })}
    >
      <td className="" style={{ maxWidth: '250px' }}>
        {/* <div className="flex items-center">
          <div>
            <Thumbnail
              source={
                'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081'
              }
            ></Thumbnail>
          </div>
          <div className="pl-3">
            <p>{item.title}</p>
            {item.variantTitle && item.variantTitle !== 'default' && (
              <TextStyle variation="subdued">{item.variantTitle}</TextStyle>
            )}
          </div>
        </div> */}
        <p>{item.title}</p>
        {item.variantTitle && item.variantTitle !== 'Default Title' && (
          <TextStyle variation="subdued">{item.variantTitle}</TextStyle>
        )}
      </td>
      <td className="">
        <p variation="subdued">
          {item._vendorSku || <em>&lt;SKU Unavailable&gt;</em>}
        </p>
      </td>
      <td className="" style={{ maxWidth: '100px' }}>
        <TextField
          type="number"
          error={formik.errors.incoming}
          value={String(formik.values.incoming)}
          onChange={async (val) => {
            const value = parseInt(val, 10) || 0
            if (value >= 0 && mounted.current) {
              await formik.setFieldValue('incoming', value)
            }

            if (value === 0 && mounted.current) {
              // reset incoming column
              await formik.setFieldValue('incomingExpectedOn', null)
            }
          }}
        />
      </td>
      <td className={`inventory-table-date-col incoming-expected-col`}>
        <DatePicker
          selected={formik.values.incomingExpectedOn}
          onChange={(date) => {
            formik.setFieldValue('incomingExpectedOn', date)
          }}
          isClearable
          dateFormat="MMMM d, yyyy"
          minDate={minDate}
        />
        {formik.errors.incomingExpectedOn && (
          <div className="absolute">
            <InlineError message={formik.errors.incomingExpectedOn} />
          </div>
        )}
      </td>
      <td
        className={`inventory-table-date-col ${
          shouldUpdateIncoming ? 'red-text' : ''
        }`}
      >
        {inventory.incomingUpdatedAt
          ? formatDate(inventory.incomingUpdatedAt)
          : 'Never updated'}
      </td>
      <td style={{ maxWidth: '100px' }}>
        <TextField
          type="number"
          value={String(formik.values.available)}
          onChange={(val) => {
            const value = parseInt(val, 10) || 0
            if (value >= 0) {
              formik.setFieldValue('available', value)
            }
          }}
        />
      </td>
      <td>
        {inventory.available < inventory.minAvailableAlertThreshold && (
          <div className="badge danger inline-block">
            Less than {inventory.minAvailableAlertThreshold} available
          </div>
        )}
      </td>
      <td className=" inventory-table-date-col">
        <div className={shouldUpdateAvailble ? 'red-text' : undefined}>
          {inventory.availableUpdatedAt
            ? formatDate(inventory.availableUpdatedAt)
            : 'Never updated'}
        </div>
      </td>
      <td className="">
        <Button
          primary
          size="slim"
          onClick={formik.submitForm}
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        >
          Save
        </Button>
      </td>
    </tr>
  )
}

InventoryLineItem.propTypes = {}

InventoryLineItem.defaultProps = {}

export default InventoryLineItem
