// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from 'react'
import firebase from '../firebase'

const authContext = createContext()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [accessToken, setAccessToken] = useState(null)

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  let _user = null
  const signin = (token) => {
    return firebase
      .auth()
      .signInWithCustomToken(token)
      .then((response) => {
        _user = response.user
        return firebase.auth().currentUser.getIdToken()
      })
      .then((token) => {
        setAccessToken(token)
        setUser(_user)
        return _user
      })
  }

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false)
        setAccessToken(null)
      })
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then((token) => {
            setUser(user)
            setAccessToken(token)
            setLoading(false)
          })
      } else {
        setUser(false)
        setLoading(false)
        setAccessToken(null)
      }
    })

    // Cleanup subscription on unmount
    return unsubscribe
  }, [])

  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
    authenticated: !!user,
    loading,
    accessToken,
  }
}

export default useAuth
