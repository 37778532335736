import firebase from 'firebase/app'
import 'firebase/auth'
import { env } from './utils'

const firebaseConfig = {
  production: {
    apiKey: 'AIzaSyDOOYjBwZ8slBps1wNUnnt4truXAO8Nee8',
    authDomain: 'wholesale-hand-sanitiser.firebaseapp.com',
    databaseURL: 'https://wholesale-hand-sanitiser.firebaseio.com',
    projectId: 'wholesale-hand-sanitiser',
    storageBucket: 'wholesale-hand-sanitiser.appspot.com',
    messagingSenderId: '473733175046',
    appId: '1:473733175046:web:2b3236bfc3fbf9bea63087',
    measurementId: 'G-HR83XT1ZBN',
  },
  development: {
    apiKey: 'AIzaSyBxwldjUKfkeL-bzoahIjv7FUTqSiRjk1Q',
    authDomain: 'wholesalehandsanitiser.firebaseapp.com',
    databaseURL: 'https://wholesalehandsanitiser.firebaseio.com',
    projectId: 'wholesalehandsanitiser',
    storageBucket: 'wholesalehandsanitiser.appspot.com',
    messagingSenderId: '105620261121',
    appId: '1:105620261121:web:b5d12bbc7a538b3afdb300',
  },
}

firebase.initializeApp(firebaseConfig[env])

export default firebase
