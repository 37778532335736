import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { queryCache } from 'react-query'

import Spinner from './Spinner'
import useAuth from '../hooks/useAuth'

function Logout() {
  const auth = useAuth()

  useEffect(() => {
    if (auth.user) {
      queryCache.clear()
      auth.signout()
    }
  }, [auth])

  const loggedOut = !auth.loading && !auth.user
  if (loggedOut) {
    return <Redirect to="/" />
  }

  return <Spinner />
}

Logout.propTypes = {}

Logout.defaultProps = {}

export default Logout
