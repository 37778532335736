import React, { useEffect, useState, useCallback } from 'react'
import {
  useRouteMatch,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'
import {
  Page,
  Layout,
  Stack,
  Button,
  Frame,
  DisplayText,
  Popover,
  ActionList,
} from '@shopify/polaris'

import FulfillmentDashboard from './FulfillmentDashboard'
import InventoryDashboard from './InventoryDashboard'
import useAuth from '../hooks/useAuth'
import useDocumentTitle from '../hooks/useDocumentTitle'
import useBodyClass from '../hooks/useBodyClass'
import { slugifyVendor } from '../utils'
import logo from '../assets/whs.png'

function Main() {
  const match = useRouteMatch()
  const auth = useAuth()
  const history = useHistory()

  const [topMenuActive, setTopMenuActive] = useState(false)

  const toggleTopMenuActive = useCallback(
    () => setTopMenuActive((active) => !active),
    []
  )

  const vendorUrlSlug = slugifyVendor(match.url.substring(1).split('/')[0])
  // const vendorName = auth.user.displayName
  const slug = auth.user.uid

  const [vendorImg, setVendorImg] = useState(null)
  useEffect(() => {
    if (vendorUrlSlug === slug) {
      import(`../assets/${slug}.png`)
        .then((module) => {
          if (module && module.default) {
            setVendorImg(module.default)
          }
        })
        .catch((e) => {
          console.log('No vendor image found')
        })
    }
  }, [vendorUrlSlug, slug])

  // Update doc title
  const isInventoryPage = window.location.pathname.includes('inventory')
  const pageTitle = isInventoryPage ? 'Stock Management' : 'Orders Fulfillment'
  useDocumentTitle(pageTitle)
  useBodyClass('inventory-page', pageTitle === 'Stock Management')

  if (vendorUrlSlug !== slug) {
    return <Redirect to={`/${slug}`} />
  }

  return (
    <Page
      fullWidth={isInventoryPage}
      title={
        <>
          <img
            src={logo}
            alt="Logo of Wholesale Hand Sanitiser"
            className="header-logo"
          />
          <span
            style={{
              fontSize: '80%',
              display: 'inline-block',
              position: 'absolute',
              paddingLeft: 25,
              top: '40%',
            }}
          >
            {pageTitle}
          </span>
        </>
      }
      primaryAction={
        <Stack alignment="center">
          {vendorImg && (
            <img
              src={vendorImg}
              alt={`Logo of ${auth.user.displayName}`}
              className="header-logo"
            />
          )}
          {!vendorImg && (
            <DisplayText size="small">{auth.user.displayName}</DisplayText>
          )}
          <Popover
            active={topMenuActive}
            activator={
              <Button onClick={toggleTopMenuActive} disclosure>
                Menu
              </Button>
            }
            onClose={toggleTopMenuActive}
          >
            <ActionList
              onActionAnyItem={toggleTopMenuActive}
              items={[
                {
                  content: 'Orders',
                  onAction: () => {
                    history.push(`/${slug}`)
                  },
                },
                {
                  content: 'Inventory',
                  onAction: () => {
                    history.push(`/${slug}/inventory`)
                  },
                },
                {
                  content: 'Logout',
                  onAction: () => {
                    if (window.confirm('Are you sure want to log out?')) {
                      auth.signout()
                    }
                  },
                },
              ]}
            />
          </Popover>
        </Stack>
      }
    >
      <Layout>
        <Frame>
          <Switch>
            <Route path={`/${slug}/inventory`} component={InventoryDashboard} />
            <Route path={`/${slug}`} component={FulfillmentDashboard} />
          </Switch>
        </Frame>
      </Layout>
    </Page>
  )
}

Main.propTypes = {}

Main.defaultProps = {}

export default Main
