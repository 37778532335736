import React from 'react'
import { Page, Button, Stack } from '@shopify/polaris'
import { useHistory } from 'react-router-dom'

function NotFound() {
  const history = useHistory()
  const handleClick = () => {
    history.push('/')
  }

  return (
    <Page title="Oops! This page doesn't exist.">
      <Stack vertical>
        <Button onClick={handleClick} primary>
          Back to home
        </Button>
      </Stack>
    </Page>
  )
}

NotFound.propTypes = {}

NotFound.defaultProps = {}

export default NotFound
