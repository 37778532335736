import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'

import Spinner from './Spinner'
import useAuth from '../hooks/useAuth'

import { slugifyVendor } from '../utils'

function PrivateRoute({ component: Component, ...rest }) {
  const auth = useAuth()
  const location = useLocation()
  const vendorUrlSlug = slugifyVendor(location.pathname.split('/')[1])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.loading) {
          return <Spinner withPage />
        }

        return auth.user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={`/login${vendorUrlSlug ? `?vendor=${vendorUrlSlug}` : ''}`}
          />
        )
      }}
    />
  )
}

PrivateRoute.propTypes = {}

PrivateRoute.defaultProps = {}

export default PrivateRoute
