import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Button, Card, Stack, Heading, Badge } from '@shopify/polaris'

import FulfillmentModal from './FulfillmentModal'

import { getNumWorkDays } from '../../utils'
import proptypes from '../proptypes'

const LAST_2_CHARS = /.{2}$/
const lowerCase = (value) => value.toLowerCase()
const formatDate = (date) =>
  format(new Date(date), 'eee dd MMM hh:mma').replace(LAST_2_CHARS, lowerCase)

const fulfillmentStatusMap = {
  partial: {
    label: 'Partially fulfilled',
    progress: 'partiallyComplete',
    status: 'warning',
  },
  fulfilled: { label: 'Fulfilled', progress: 'complete', status: undefined },
  _default: {
    label: 'Unfulfilled',
    progress: 'incomplete',
    status: 'attention',
  },
}

const getFulfillmentStatus = (fulfilmentStatus) => {
  return fulfillmentStatusMap[fulfilmentStatus] || fulfillmentStatusMap._default
}

function FulfillmentLineItem({
  orderNode,
  onFulfillment,
  disabled = false,
  accessToken,
}) {
  const [fulfillModalActive, setFulfilModalActive] = useState(false)

  const alreadyFulfilled = orderNode._vendorFulfillmentStatus === 'fulfilled'

  const orderStatusBadge = getFulfillmentStatus(
    orderNode._vendorFulfillmentStatus
  )

  const businessDaysOld =
    (window.AU_CURRENT_TIME &&
      getNumWorkDays(new Date(orderNode.createdAt), window.AU_CURRENT_TIME)) ||
    0

  const title = (
    <Stack alignment="center">
      <Stack.Item fill>
        <Stack>
          <Stack.Item fill>
            <Button plain onClick={() => setFulfilModalActive(true)}>
              <Heading>
                {orderNode.name} - {orderNode.customer.displayName}
              </Heading>
            </Button>
          </Stack.Item>
          <Stack.Item>
            <p>{formatDate(orderNode.createdAt)}</p>
          </Stack.Item>
          <Stack.Item>
            {businessDaysOld >= 2 && (
              <Badge status="critical">{businessDaysOld} days old</Badge>
            )}
            <Badge
              progress={orderStatusBadge.progress}
              status={orderStatusBadge.status}
            >
              {orderStatusBadge.label}
            </Badge>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack>
          <Stack.Item fill></Stack.Item>
          <Stack.Item>
            <Button
              primary
              size="slim"
              onClick={() => setFulfilModalActive(true)}
              disabled={disabled || alreadyFulfilled}
            >
              Mark as fulfilled
            </Button>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  )

  return (
    <>
      <FulfillmentModal
        orderNode={orderNode}
        active={fulfillModalActive}
        closeModal={() => setFulfilModalActive(false)}
        onFulfillment={onFulfillment}
        accessToken={accessToken}
      />

      <div disabled={disabled} className="mb-2 fulfillment-line-item">
        <Card sectioned>
          {title}
          <div className="-1mt-16"></div>
        </Card>
      </div>
    </>
  )
}

FulfillmentLineItem.propTypes = {
  disabled: PropTypes.bool,
  orderNode: proptypes.orderNode,
  onFulfillment: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
}

FulfillmentLineItem.defaultProps = {}

export default FulfillmentLineItem
