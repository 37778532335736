import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  TextField,
  Layout,
  Stack,
  TextStyle,
  Button,
  Card,
  Heading,
  Toast,
} from '@shopify/polaris'
import { useInfiniteQuery, queryCache } from 'react-query'
import { cloneDeep } from 'lodash'

import InventoryLineItem from './InventoryLineItem'
import Spinner from '../Spinner'
import useAuth from '../../hooks/useAuth'
import useDebounceValue from '../../hooks/useDebounceValue'
import { backendURL } from '../../utils'
import { useEffect } from 'react'

function updateVariantsCache({ updatedItem }) {
  const prevVariantsGroup = queryCache.getQueryData('inventory-items')
  const updatedVariantsGroup = cloneDeep(prevVariantsGroup)

  updatedVariantsGroup.forEach((group) => {
    group.data.edges.forEach((variantEdge) => {
      if (variantEdge.node.id === updatedItem.id) {
        variantEdge.node = updatedItem
      }
    })
  })

  queryCache.setQueryData('inventory-items', updatedVariantsGroup)
}

function InventoryDashboard() {
  const auth = useAuth()
  const accessToken = auth.accessToken
  const autoFetchDelay = useRef(3000)

  const [searchVal, setSearchVal] = useState(null)
  const debouncedSearchValue = useDebounceValue(searchVal, 800)

  const [toastMsg, setToastMsg] = useState(null)
  const hideToast = () => setToastMsg(null)

  const invItemsQuery = useInfiniteQuery(
    'inventory-items',
    (key, cursor) => {
      const source = axios.CancelToken.source()

      const promise = axios
        .get(`${backendURL}/api/inventory-items`, {
          params: {
            after: cursor,
            search: debouncedSearchValue,
          },
          headers: { authorization: `Bearer ${accessToken}` },
          cancelToken: source.token,
        })
        .then((response) => response.data)

      // Cancel the request if React Query calls the `promise.cancel` method
      promise.cancel = () => {
        source.cancel('Query was cancelled by React Query')
      }

      return promise
    },
    {
      retry: false,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      getFetchMore: (lastGroup) =>
        lastGroup.data &&
        lastGroup.data.pageInfo &&
        lastGroup.data.pageInfo.hasNextPage
          ? lastGroup.data._lastCursor
          : null,
      staleTime: 1000 * 60 * 60 * 20, // 20 min
      refetchOnWindowFocus: false,
    }
  )

  // Auto fetch
  const { canFetchMore, isFetching, status, fetchMore } = invItemsQuery
  useEffect(() => {
    const isLocalhost = window.location.host.split(':')[0] === 'localhost'
    if (isLocalhost) return

    if (canFetchMore && !isFetching && status !== 'loading') {
      setTimeout(() => {
        console.log(`Feching more after ${autoFetchDelay.current}`)
        fetchMore()
        autoFetchDelay.current = 8000
      }, autoFetchDelay.current)
    }
  }, [canFetchMore, isFetching, status, fetchMore])

  useEffect(() => {
    if (debouncedSearchValue !== null) {
      queryCache.invalidateQueries('inventory-items')
    }
  }, [debouncedSearchValue])

  const initialLoading = invItemsQuery.status === 'loading'

  const invItemEdges = (invItemsQuery.data || []).reduce((combined, group) => {
    combined.push(...group.data.edges)
    return combined
  }, [])

  const erred = invItemsQuery.status === 'error'

  const minDate = useMemo(() => {
    return window.AU_CURRENT_TIME
  }, [window.AU_CURRENT_TIME_STRING])

  return (
    <>
      {toastMsg && <Toast content={toastMsg} onDismiss={hideToast} />}
      {erred && (
        <Card sectioned>
          <TextStyle variation="negative">
            {invItemsQuery.error.response?.data?.error?.message ||
              invItemsQuery.error.message}
          </TextStyle>
        </Card>
      )}

      {!erred && !initialLoading && invItemEdges.length > 0 && (
        <div className="mb-6">
          <Stack>
            <Stack.Item fill></Stack.Item>
            <Stack.Item>
              {invItemsQuery.canFetchMore ? (
                <Heading element="h3">
                  More than {invItemEdges.length} products
                </Heading>
              ) : (
                <Heading element="h3">{invItemEdges.length} products</Heading>
              )}
            </Stack.Item>
            <Stack.Item fill></Stack.Item>
          </Stack>
        </div>
      )}

      <div className="inventory-dashboard">
        <Layout.Section>
          <div className="max-w-xl mx-auto">
            <TextField
              value={searchVal === null ? '' : searchVal}
              onChange={(val) => {
                setSearchVal(val)
              }}
              label="Search products"
              labelHidden
              type="text"
              placeholder="Search products"
              disabled={initialLoading}
              clearButton
              onClearButtonClick={() => setSearchVal('')}
              autoFocus={typeof searchVal === 'string'}
            />
          </div>

          {initialLoading && <Spinner reason="Loading products" />}

          <Layout.Section>
            {!erred && !initialLoading && invItemEdges.length > 0 && (
              <table
                className="inventory-table mx-auto mt-4"
                style={{
                  minWidth: 1200,
                  overflowX: 'auto',
                }}
              >
                <thead>
                  <tr className="">
                    <th className="">Product</th>
                    <th className="">SKU</th>
                    <th className="">Incoming</th>
                    <th className="">Incoming expected on</th>
                    <th className="inventory-table-date-col">
                      Incoming last updated
                    </th>
                    <th className="">Available</th>
                    <th className=""></th>
                    <th className="inventory-table-date-col">
                      Available last updated
                    </th>
                    <th className=""></th>
                  </tr>
                </thead>
                <tbody>
                  {invItemEdges.map(({ node: item }) => (
                    <InventoryLineItem
                      item={item}
                      key={`${item.id}-${item._inventory.available}-${item._inventory.incoming}`}
                      accessToken={accessToken}
                      onUpdate={(updatedItem) => {
                        updateVariantsCache({ updatedItem })
                        setToastMsg('Updated')
                      }}
                      minDate={minDate}
                    />
                  ))}
                </tbody>
              </table>
            )}

            {!erred && !initialLoading && invItemEdges.length === 0 && (
              <div className="mt-4">
                <Card sectioned>
                  <Heading element="h2">No Products.</Heading>
                </Card>
              </div>
            )}

            {!erred && !initialLoading && invItemsQuery.canFetchMore && (
              <div className="mt-4">
                <Stack>
                  <Stack.Item fill />
                  <Button
                    size="medium"
                    primary
                    disabled={invItemsQuery.isFetchingMore}
                    loading={invItemsQuery.isFetchingMore}
                    onClick={() => invItemsQuery.fetchMore()}
                  >
                    Load more products
                  </Button>
                  <Stack.Item fill />
                </Stack>
              </div>
            )}
          </Layout.Section>
        </Layout.Section>
      </div>
    </>
  )
}

InventoryDashboard.propTypes = {}

InventoryDashboard.defaultProps = {}

export default InventoryDashboard
