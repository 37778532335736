import React, { useState, useEffect, useRef } from 'react'
import {
  Layout,
  Stack,
  Button,
  TextStyle,
  Toast,
  Heading,
  Card,
  List,
  TextContainer,
} from '@shopify/polaris'
import axios from 'axios'
import { useInfiniteQuery, queryCache } from 'react-query'
import { cloneDeep } from 'lodash'

import FulfillmentLineItem from './FulfillmentLineItem'
import Spinner from '../Spinner'
import useAuth from '../../hooks/useAuth'
import { backendURL } from '../../utils'

function updateOrdersCache({ orderId, accessToken }) {
  const prevOrdersGroup = queryCache.getQueryData('orders')
  const updatedOrdersGroup = cloneDeep(prevOrdersGroup)

  return axios
    .get(`${backendURL}/api/order`, {
      params: { id: orderId },
      headers: { authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      const newOrder = res.data.data

      if (newOrder._vendorFulfillmentStatus === 'fulfilled') {
        updatedOrdersGroup.forEach((group) => {
          group.data.edges = group.data.edges.filter((orderEdge) => {
            return orderEdge.node.id !== newOrder.id
          })
        })
      } else {
        updatedOrdersGroup.forEach((group) => {
          group.data.edges.forEach((orderEdge, index) => {
            if (orderEdge.node.id === newOrder.id) {
              group.data.edges[index].node = newOrder
            }
          })
        })
      }

      queryCache.setQueryData('orders', updatedOrdersGroup)
    })
}

function OrderEditDashboard() {
  const auth = useAuth()
  const autoFetchDelay = useRef(2000)

  const [updatingCache, setUpdatingCache] = useState(false)
  const [toastMsg, setToastMsg] = useState(null)
  const hideToast = () => setToastMsg(null)

  const ordersQuery = useInfiniteQuery(
    'orders',
    (key, cursor) => {
      const source = axios.CancelToken.source()

      const promise = axios
        .get(`${backendURL}/api/orders`, {
          params: {
            after: cursor,
          },
          headers: { authorization: `Bearer ${auth.accessToken}` },
          cancelToken: source.token,
        })
        .then((response) => response.data)

      // Cancel the request if React Query calls the `promise.cancel` method
      promise.cancel = () => {
        source.cancel('Query was cancelled by React Query')
      }

      return promise
    },
    {
      refetchOnMount: true,
      retry: false,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      getFetchMore: (lastGroup) =>
        lastGroup.data &&
        lastGroup.data.pageInfo &&
        lastGroup.data.pageInfo.hasNextPage
          ? lastGroup.data._lastCursor
          : null,
      staleTime: 1000 * 60 * 60 * 20, // 20 min
      refetchOnWindowFocus: false,
    }
  )

  // Auto fetch
  useEffect(() => {
    if (
      ordersQuery.canFetchMore &&
      !ordersQuery.isFetching &&
      ordersQuery.status !== 'loading'
    ) {
      setTimeout(() => {
        console.log(`Feching more after ${autoFetchDelay.current}`)
        ordersQuery.fetchMore()
        autoFetchDelay.current = 5000
      }, autoFetchDelay.current)
    }
  }, [ordersQuery])

  const initialLoading =
    ordersQuery.status === 'loading' ||
    (ordersQuery.isFetching && !ordersQuery.isFetchingMore)

  if (initialLoading) {
    return <Spinner reason="Loading orders" />
  }

  const orderEdges = ordersQuery.data.reduce((combined, group) => {
    combined.push(...group.data.edges)
    return combined
  }, [])

  const erred = ordersQuery.status === 'error'

  return (
    <>
      {toastMsg && <Toast content={toastMsg} onDismiss={hideToast} />}

      <Layout.Section>
        {erred && (
          <Card sectioned>
            <TextStyle variation="negative">
              {ordersQuery.error.response?.data?.error?.message ||
                ordersQuery.error.message}
            </TextStyle>
          </Card>
        )}

        {!erred && (
          <div className="mb-10">
            <TextContainer>
              <p>
                Welcome to your Wholesale Hand Sanitiser Fulfilment Dashboard.
                The purpose of this dashboard is to keep your deliveries on
                track and communicate efficient, reliable information back to
                the customer about their order.
              </p>

              <Heading element="h3">INSTRUCTIONS:</Heading>

              <List type="number">
                <List.Item>
                  Each order must be marked as Fulfilled or Partially Fulfilled
                  on the same day as parcel pick-up.
                </List.Item>
                <List.Item>Tracking Numbers must be added.</List.Item>
                <List.Item>
                  A Packing Slip must be printed and included with each
                  shipment.
                </List.Item>
              </List>
            </TextContainer>
          </div>
        )}

        {!erred && orderEdges.length === 0 && (
          <div className="mt-4">
            <Card sectioned>
              <Heading element="h2">
                There are no unfulfilled orders at the moment.
              </Heading>
            </Card>
          </div>
        )}

        {!erred && orderEdges.length > 0 && (
          <div className="mb-6">
            <Stack>
              <Stack.Item fill></Stack.Item>
              <Stack.Item>
                {ordersQuery.canFetchMore && (
                  <Heading element="h3">
                    More than {orderEdges.length} unfulfilled orders
                  </Heading>
                )}
                {!ordersQuery.canFetchMore && (
                  <Heading element="h3">
                    {orderEdges.length} unfulfilled orders
                  </Heading>
                )}
              </Stack.Item>
              <Stack.Item fill></Stack.Item>
            </Stack>
          </div>
        )}

        {!erred &&
          orderEdges.map(({ node: orderNode }) => (
            <FulfillmentLineItem
              key={orderNode.id}
              orderNode={orderNode}
              disabled={updatingCache === orderNode.id}
              accessToken={auth.accessToken}
              onFulfillment={(ord, { remainingQty }) => {
                setToastMsg(
                  `${ord.name} is ${
                    remainingQty > 0 ? 'partially' : ''
                  } fulfilled`
                )

                setUpdatingCache(ord.id)

                updateOrdersCache({
                  orderId: ord.id,
                  accessToken: auth.accessToken,
                }).then(() => {
                  setUpdatingCache(false)
                })
              }}
            />
          ))}

        {!erred && ordersQuery.canFetchMore && (
          <div className="mt-4">
            <Stack>
              <Stack.Item fill />
              <Button
                size="medium"
                primary
                disabled={ordersQuery.isFetchingMore}
                loading={ordersQuery.isFetchingMore}
                onClick={() => ordersQuery.fetchMore()}
              >
                Load more orders
              </Button>
              <Stack.Item fill />
            </Stack>
          </div>
        )}
      </Layout.Section>
    </>
  )
}

export default OrderEditDashboard
