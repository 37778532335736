import React, { useState } from 'react'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { queryCache } from 'react-query'
import axios from 'axios'
import qs from 'qs'
import {
  Button,
  Page,
  Card,
  Form,
  FormLayout,
  TextField,
  Layout,
  InlineError,
} from '@shopify/polaris'

import useAuth from '../hooks/useAuth'

import { slugifyVendor, backendURL } from '../utils'
import logo from '../assets/whs.png'

let firebaseAuthSessionErrorCount = 0

const validate = (values) => {
  const errors = {}
  const slugified = slugifyVendor(values.vendor)

  if (slugified.length === 0) {
    errors.vendor = 'Required'
  } else if (slugified.length < 3) {
    errors.vendor = 'Should contain at least 3 characters'
  }

  if (values.password.length < 3) {
    errors.password = 'Should contain at least 3 characters'
  }

  return errors
}

function Login() {
  const [err, setErr] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const auth = useAuth()
  const params = qs.parse((location.search || '').replace(/^\?/, ''))

  const formik = useFormik({
    initialValues: {
      vendor: params.vendor || '',
      password: '',
    },
    validate,
    onSubmit: (values, { setSubmitting }) => {
      queryCache.clear()
      const { vendor, password } = values
      const slugified = slugifyVendor(values.vendor)

      if (vendor.length > 2 && password.length > 2) {
        setErr(null)
        setSubmitting(true)

        axios
          .post(`${backendURL}/api/login`, { vendor, password })
          .then((res) => {
            const { data } = res
            return auth.signin(data.token)
          })
          .then(() => {
            history.push(`/${slugified.toLowerCase()}`)
          })
          .catch((error) => {
            setSubmitting(false)
            if (error.response) {
              // Request made and server responded
              console.log('Status', error.response.status)
              setErr(
                error.response.data?.error?.message || 'Invalid credentials'
              )
              console.log(error.response.data)
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request)
              setErr('Server error. Please try again in a moment.')
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message)
              firebaseAuthSessionErrorCount++
              if (firebaseAuthSessionErrorCount >= 3) {
                setErr(
                  'Oops! Unable to persist session. Try refreshing this page or clear the browser cache.'
                )
              } else {
                setErr(
                  'Unable to persist session. Please try again in a moment.'
                )
              }
            }
            console.log(error)
          })
      }
    },
  })

  if (auth.user) {
    return <Redirect to={`/${auth.user.uid}`} />
  }

  return (
    <Page
      title={
        <img
          src={logo}
          alt="Wholesale Hand Sanitiser"
          className="header-logo"
        />
      }
      narrowWidth
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <Form onSubmit={formik.handleSubmit}>
              <FormLayout>
                {err && <InlineError message={err} fieldID="form" />}

                <TextField
                  disabled={formik.isSubmitting}
                  name="vendor"
                  id="vendor"
                  autoFocus={!params.vendor}
                  value={formik.values.vendor}
                  onChange={(value) => {
                    formik.setFieldValue('vendor', value)
                  }}
                  label="Fulfillment Service Name"
                  type="text"
                  error={formik.touched.vendor && formik.errors.vendor}
                  onBlur={formik.handleBlur}
                />
                <TextField
                  disabled={formik.isSubmitting}
                  name="password"
                  id="password"
                  autoFocus={!!params.vendor}
                  value={formik.values.password}
                  onChange={(value) => {
                    formik.setFieldValue('password', value)
                  }}
                  label="Password"
                  type="password"
                  error={formik.touched.password && formik.errors.password}
                  onBlur={formik.handleBlur}
                />
                <Button
                  submit
                  primary
                  loading={formik.isSubmitting}
                  disabled={
                    !formik.isValid ||
                    formik.isValidating ||
                    !formik.dirty ||
                    formik.isSubmitting
                  }
                >
                  Login
                </Button>
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

Login.propTypes = {}

Login.defaultProps = {}

export default Login
