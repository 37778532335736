import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { AppProvider } from '@shopify/polaris'
import enTranslations from '@shopify/polaris/locales/en.json'
import '@shopify/polaris/styles.css'
import './App.scss'

import PrivateRoute from './components/PrivateRoute'
import Login from './components/Login'
import Logout from './components/Logout'
import NotFound from './components/NotFound'
import Main from './components/Main'

import { ProvideAuth } from './hooks/useAuth'

import { fetchCurrentTime } from './utils'

window.AU_CURRENT_TIME = new Date()
window.AU_CURRENT_TIME_STRING = window.AU_CURRENT_TIME.toISOString()

function fetchTime() {
  fetchCurrentTime().then((time) => {
    window.AU_CURRENT_TIME = time
    window.AU_CURRENT_TIME_STRING = time.toISOString()
  })
}
fetchTime()

const TEN_MINS_MS = 1000 * 60 * 10
setInterval(fetchTime, TEN_MINS_MS)

function App() {
  return (
    <ProvideAuth>
      <AppProvider i18n={enTranslations} linkComponent={Link}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/404" component={NotFound} />
          <PrivateRoute path="/:vendorName" component={Main} />
          <Route path="/" exact component={Login} />
        </Switch>
      </AppProvider>
    </ProvideAuth>
  )
}

export default App
