import PropTypes from 'prop-types'

const orderNode = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  email: PropTypes.string,
  _vendorFulfillmentStatus: PropTypes.string,
  customer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    displayName: PropTypes.string,
  }),
  lineItems: PropTypes.shape({
    pageInfo: PropTypes.shape({
      hasNextPage: PropTypes.bool,
    }),
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        cursor: PropTypes.string,
        node: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          quantity: PropTypes.number,
          fulfillableQuantity: PropTypes.number,
          fulfillmentStatus: PropTypes.string,
          sku: PropTypes.string,
          title: PropTypes.string,
          variantTitle: PropTypes.string,
          vendor: PropTypes.string,
        }),
      })
    ),
  }),
}).isRequired

export default { orderNode }
