import React from 'react'
import PropTypes from 'prop-types'
import {
  Page,
  Card,
  Layout,
  Spinner as PolarisSpinner,
  Stack,
} from '@shopify/polaris'
import logo from '../assets/whs.png'

function Spinner({
  pageTitle = 'Wholesale Hand Sanitiser',
  reason = 'Loading',
  withPage = false,
}) {
  const spinner = (
    <Layout.Section>
      <Card sectioned>
        <Stack>
          <Stack.Item fill />

          <Stack.Item>
            <PolarisSpinner
              accessibilityLabel={reason}
              size="large"
              color="teal"
            />
          </Stack.Item>

          <Stack.Item fill />
        </Stack>
      </Card>
    </Layout.Section>
  )

  return withPage ? (
    <Page
      title={
        <img
          src={logo}
          alt="Logo of Wholesale Hand Sanitiser"
          className="header-logo"
        />
      }
    >
      <Layout>{spinner}</Layout>
    </Page>
  ) : (
    spinner
  )
}

Spinner.propTypes = {
  pageTitle: PropTypes.string,
  reason: PropTypes.string,
  withPage: PropTypes.bool,
}

export default Spinner
