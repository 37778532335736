import React, {
  useReducer,
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import {
  Checkbox,
  DataTable,
  Button,
  ButtonGroup,
  FormLayout,
  Stack,
  TextField,
  TextStyle,
  Select,
  Thumbnail,
} from '@shopify/polaris'
import { Modal } from '@shopify/polaris'
import axios from 'axios'

import proptypes from '../proptypes'
import { backendURL, TRACKING_COMPANY_NAMES } from '../../utils'

function sleep(ms = 2000) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
const mocked = false

const getInitialState = (orderNode) =>
  orderNode.lineItems.edges.reduce((map, { node: lineItemNode }) => {
    map[lineItemNode.id] = lineItemNode.fulfillableQuantity
    return map
  }, {})

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return getInitialState(action.orderNode)
    case 'CHANGE_QTY':
      return { ...state, [action.itemId]: action.qty }

    default:
      return state
  }
}

const URL_MATCH_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
const isURL = (string) => URL_MATCH_REGEX.test(string)
const validate = (values) => {
  const errors = {}
  const trackingNum = values.trackingNum.trim()
  const trackingCompany = values.trackingCompany.trim()
  const otherCompanyTrackUrl = values.otherCompanyTrackUrl.trim()

  if (trackingNum.length === 0) {
    errors.trackingNum = 'Required'
  }

  if (trackingNum.length > 45) {
    errors.trackingNum = 'Max 45 characters allowed'
  }

  if (!trackingCompany) {
    errors.trackingCompany = 'Select a company'
  } else if (trackingCompany.length > 30) {
    errors.trackingCompany = 'Max 30 characters allowed'
  }

  if (otherCompanyTrackUrl.length > 1000) {
    errors.otherCompanyTrackUrl = 'Too much characters!'
  } else if (trackingCompany === 'Other' && !isURL(otherCompanyTrackUrl)) {
    errors.otherCompanyTrackUrl = 'Must be a valid URL'
  }

  return errors
}

function PartialFulfillmentModal({
  orderNode,
  active,
  closeModal,
  onFulfillment,
  accessToken,
}) {
  const [detailsConfirmed, setDetailsConfirmed] = useState(false)
  const handleDetailsConfirmedChange = useCallback(
    (newChecked) => setDetailsConfirmed(newChecked),
    []
  )
  const modalRef = useRef(null)

  const title = orderNode
    ? `${orderNode.name} - ${orderNode.customer.displayName}`
    : ''

  const [itemQuantities, disptatch] = useReducer(
    reducer,
    getInitialState(orderNode)
  )

  useEffect(() => {
    if (active) {
      disptatch({ type: 'RESET', orderNode })
    }
  }, [active, orderNode])

  const totalFulfillableQtys = useMemo(() => {
    return orderNode.lineItems.edges.reduce(
      (sum, itemEdge) => sum + itemEdge.node.fulfillableQuantity,
      0
    )
  }, [orderNode])

  const formik = useFormik({
    initialValues: {
      trackingNum: '',
      trackingCompany: 'Direct Freight',
      otherCompanyTrackUrl: 'https://www.directfreight.com.au/',
    },
    validateOnMount: true,
    validate,
    onSubmit: (values, { setSubmitting, setStatus, resetForm }) => {
      setSubmitting(true)
      setStatus(null)

      const lineItems = Object.keys(itemQuantities).map((id) => ({
        id: Number(id),
        quantity: itemQuantities[id],
      }))

      const totalFulfillingQty = lineItems.reduce(
        (sum, lineItem) => sum + lineItem.quantity,
        0
      )
      const remainingQty = totalFulfillableQtys - totalFulfillingQty

      const body = {
        orderId: orderNode.id,
        lineItems,
        trakingNumer: values.trackingNum.trim() || null,
        trackingUrl: values.otherCompanyTrackUrl.trim() || null,
        trackingCompany: values.trackingCompany.trim() || null,
      }

      console.log(body)
      if (mocked) {
        sleep().then(() => {
          setSubmitting(false)
          setStatus(null)
          resetForm()
          setDetailsConfirmed(false)

          closeModal()
        })
      } else {
        axios
          .post(`${backendURL}/api/fulfill-order`, body, {
            headers: { authorization: `Bearer ${accessToken}` },
          })
          .then((response) => {
            setSubmitting(false)
            setStatus(null)
            resetForm()
            setDetailsConfirmed(false)

            closeModal()
            onFulfillment(orderNode, { lineItems, remainingQty })
            return response.data
          })
          .catch((err) => {
            console.error(err.message)
            console.error(err.response)
            setSubmitting(false)
            setDetailsConfirmed(false)
            setStatus(
              'Unexpected error occured. Please try again or report the error to the admin.'
            )
          })
      }
    },
  })

  const alreadyFulfilled = orderNode._vendorFulfillmentStatus === 'fulfilled'

  const handleClose = () => {
    if (!formik.isSubmitting) {
      closeModal()
      setDetailsConfirmed(false)
    }
  }

  const handlePackingSlipClick = () => {
    const body = {
      orderId: orderNode.id,
      lineItems: Object.keys(itemQuantities).map((id) => ({
        id: Number(id),
        quantity: itemQuantities[id],
      })),
    }
    window.open(
      `${backendURL}/api/packing-slip?body=${JSON.stringify(body)}`,
      '_blank'
    )
    window.focus()
  }

  // If all quantities are zero, means invalid
  const invalidQties = Object.keys(itemQuantities).every(
    (itemId) => itemQuantities[itemId] === 0
  )

  const rows = orderNode.lineItems.edges.map(({ node: lineItemNode }) => [
    <div key={0}>
      <Stack>
        <Stack.Item>
          <Thumbnail
            source={
              (lineItemNode.image && lineItemNode.image.transformedSrc) ||
              'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081'
            }
          ></Thumbnail>
        </Stack.Item>

        <Stack.Item>
          <p>{lineItemNode.title}</p>
          <TextStyle variation="subdued">{lineItemNode.variantTitle}</TextStyle>
          <p>
            <TextStyle variation="subdued">
              SKU: {lineItemNode.sku || '<SKU Unavailable>'}
            </TextStyle>
          </p>
        </Stack.Item>
      </Stack>
    </div>,
    <div key={1} className="fulfullment-item">
      <TextField
        disabled={formik.isSubmitting || detailsConfirmed}
        type="number"
        labelHidden
        value={itemQuantities[lineItemNode.id].toString()}
        onChange={(newQty) => {
          const numNewQty = parseInt(newQty, 10)
          if (numNewQty > -1 && numNewQty <= lineItemNode.fulfillableQuantity) {
            disptatch({
              type: 'CHANGE_QTY',
              itemId: lineItemNode.id,
              qty: numNewQty,
            })
          }
        }}
        connectedRight={
          <div className="max-quanitity">
            <TextField
              type="text"
              labelHidden
              value={`of ${lineItemNode.fulfillableQuantity}`}
              disabled
            />
          </div>
        }
      />
    </div>,
  ])

  const remainingItemsQty =
    totalFulfillableQtys -
    Object.keys(itemQuantities).reduce((sum, id) => sum + itemQuantities[id], 0)

  const partiallyFulfilling = remainingItemsQty > 0

  return (
    <>
      <Modal
        size="Large"
        open={active}
        onClose={handleClose}
        title={title}
        footer={
          <>
            <div className="flex flex-end">
              <Checkbox
                disabled={!formik.isValid || invalidQties}
                label="Confirm that quantities are correct"
                checked={detailsConfirmed}
                onChange={handleDetailsConfirmedChange}
              ></Checkbox>
            </div>
            <div
              className={`mt-4 content text-right ${
                detailsConfirmed ? '' : 'visible-hidden'
              }`}
            >
              <TextStyle variation="subdued">
                Don&apos;t forget to download the packing slip.
              </TextStyle>
            </div>

            <div
              className={`content text-right ${
                detailsConfirmed ? '' : 'visible-hidden'
              }`}
            >
              <TextStyle variation="negative">
                The action cannot be reversed.
              </TextStyle>
            </div>
            <div className="mt-4">
              <Stack>
                <Stack.Item fill></Stack.Item>
                <Stack.Item>
                  <ButtonGroup>
                    <Button
                      onClick={handleClose}
                      disabled={formik.isSubmitting}
                    >
                      Close
                    </Button>
                    <div className="green-button" key="packingslip">
                      <Button
                        disabled={invalidQties || !detailsConfirmed}
                        size="slim"
                        onClick={handlePackingSlipClick}
                      >
                        Packing slip
                      </Button>
                    </div>
                    <Button
                      size="slim"
                      primary
                      onClick={() => formik.submitForm()}
                      loading={formik.isSubmitting}
                      disabled={
                        !detailsConfirmed ||
                        !formik.isValid ||
                        invalidQties ||
                        formik.isSubmitting ||
                        alreadyFulfilled ||
                        !formik.dirty
                      }
                    >
                      {partiallyFulfilling
                        ? `Partially fulfil order${orderNode.name}`
                        : `Fulfil entire order${orderNode.name}`}
                    </Button>
                  </ButtonGroup>
                </Stack.Item>
              </Stack>
            </div>
          </>
        }
      >
        <Modal.Section>
          {active && (
            <div className="partial-fulfillment" ref={modalRef}>
              <DataTable
                columnContentTypes={['text', 'number']}
                headings={['Items', 'Quantity']}
                rows={rows}
              />

              <div style={{ marginTop: '30px' }}>
                <FormLayout>
                  <Stack>
                    <TextStyle variation="strong">
                      Tracking Information:
                    </TextStyle>
                  </Stack>

                  <Stack alignment="leading">
                    <Stack.Item fill>
                      <TextField
                        disabled={formik.isSubmitting || detailsConfirmed}
                        placeholder="Tracking Number*"
                        value={formik.values.trackingNum}
                        onChange={async (tNum) => {
                          formik.setFieldValue('trackingNum', tNum)
                        }}
                        onBlur={() => {
                          if (!formik.touched.trackingNum) {
                            formik.setFieldTouched('trackingNum', true)
                          }
                        }}
                        error={
                          formik.touched.trackingNum &&
                          formik.errors.trackingNum
                        }
                      />
                    </Stack.Item>

                    <Stack.Item fill>
                      <Select
                        disabled={formik.isSubmitting || detailsConfirmed}
                        options={TRACKING_COMPANY_NAMES}
                        onChange={async (company) => {
                          await formik.setFieldValue('trackingCompany', company)
                          if (company === 'Direct Freight') {
                            await formik.setFieldValue(
                              'otherCompanyTrackUrl',
                              'https://www.directfreight.com.au/'
                            )
                          } else if (company === 'Choose Service') {
                            await formik.setFieldValue('trackingCompany', '')
                            await formik.setFieldValue(
                              'otherCompanyTrackUrl',
                              ''
                            )
                          } else {
                            await formik.setFieldValue(
                              'otherCompanyTrackUrl',
                              ''
                            )
                          }
                        }}
                        onBlur={() => {
                          if (!formik.touched.trackingCompany) {
                            formik.setFieldTouched('trackingCompany', true)
                          }
                        }}
                        value={formik.values.trackingCompany}
                        error={
                          formik.touched.trackingCompany &&
                          formik.errors.trackingCompany
                        }
                      />
                    </Stack.Item>
                  </Stack>

                  {formik.values.trackingCompany === 'Other' && (
                    <Stack spacing="extraLoose">
                      <Stack.Item fill>
                        <TextField
                          disabled={formik.isSubmitting || detailsConfirmed}
                          label="Tracking URL"
                          helpText="Enter the tracking page link for this order."
                          value={formik.values.otherCompanyTrackUrl}
                          error={
                            formik.touched.otherCompanyTrackUrl &&
                            formik.errors.otherCompanyTrackUrl
                          }
                          onChange={(value) => {
                            formik.setFieldValue('otherCompanyTrackUrl', value)
                          }}
                          onBlur={() => {
                            if (!formik.touched.otherCompanyTrackUrl) {
                              formik.setFieldTouched(
                                'otherCompanyTrackUrl',
                                true
                              )
                            }
                          }}
                        />
                      </Stack.Item>
                    </Stack>
                  )}

                  {formik.status && (
                    <TextStyle variation="negative">{formik.status}</TextStyle>
                  )}
                </FormLayout>
              </div>
            </div>
          )}
        </Modal.Section>
      </Modal>
    </>
  )
}

PartialFulfillmentModal.propTypes = {
  orderNode: proptypes.orderNode,
  active: PropTypes.bool,
  closeModal: PropTypes.func,
  onFulfillment: PropTypes.func,
  children: PropTypes.node,
  accessToken: PropTypes.string.isRequired,
}

PartialFulfillmentModal.defaultProps = {
  active: false,
  closeModal: () => {},
  onFulfillment: () => {},
}

export default PartialFulfillmentModal
