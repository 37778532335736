import { useLayoutEffect } from 'react'

export default function useBodyClass(cssClass, condition) {
  useLayoutEffect(() => {
    if (typeof cssClass !== 'string' || typeof condition !== 'boolean') {
      console.warn(`[useBodyClass] - Invalid argument types`)
      return
    }

    if (condition) {
      if (!document.body.classList.contains(cssClass)) {
        document.body.classList.add(cssClass)
      }
    } else {
      if (document.body.classList.contains(cssClass)) {
        document.body.classList.remove(cssClass)
      }
    }
  }, [cssClass, condition])
}
