import axios from 'axios'

const NON_ALPHA_NUM = /[^a-zA-Z-_]+/gi
const MULTIPLE_SPACES = /\s+/gi
const MULTIPLE_HYPHENS = /-+/gi
const MULTIPLE_UNDERSCORES = /_+/gi

export const slugifyVendor = (value) =>
  value
    .replace(MULTIPLE_SPACES, '-')
    .replace(MULTIPLE_HYPHENS, '-')
    .replace(MULTIPLE_UNDERSCORES, '_')
    .replace(NON_ALPHA_NUM, '')
    .toLowerCase()

const TRACKING_COMPANIES = [
  { name: 'Australia Post', pattern: '^([A-Z]{2}\\d{9}AU$|^\\d{20,23}$)' },
]

export const TRACKING_COMPANY_NAMES = [
  'Choose Service',
  ...TRACKING_COMPANIES.map((c) => c.name).sort(),
  'Direct Freight',
  'Other',
]

export const detectTrackingCompany = (trackingNum) => {
  const stringifiedTrackingNumber = String(trackingNum || '')

  if (stringifiedTrackingNumber.trim().length === 0) {
    return 'Choose Service'
  }

  return (
    TRACKING_COMPANIES.filter(({ pattern }) => {
      const reg = new RegExp(pattern)
      return reg.test(stringifiedTrackingNumber)
    }).map((p) => p.name)[0] || 'Other'
  )
}

const host = window.location.host.split(':')[0]
const DEV_HOSTS = ['localhost', 'wholesalehandsanitiser.web.app']

export const env = DEV_HOSTS.includes(host) ? 'development' : 'production'
const isLocalHost = host === 'localhost'
export const backendURL = {
  development: isLocalHost
    ? 'http://localhost:5001/wholesalehandsanitiser/us-central1'
    : `${window.location.protocol}//${window.location.host}`,
  production: `${window.location.protocol}//${window.location.host}`,
}[env]

function addDays(date, days) {
  const newDate = new Date(date.getTime())
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

export function getNumWorkDays(_startDate, _endDate) {
  const MAX_TIME = 1 * 24 * 60 * 60 * 1000

  let numWorkDays = 0
  let startDate = new Date(_startDate.getTime())
  const endDate = new Date(_endDate.getTime())

  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(0, 0, 0, 0)

  while (startDate < endDate) {
    const diff = endDate.getTime() - startDate.getTime()
    if (diff < MAX_TIME) break

    const saturday = startDate.getDay() === 6
    const sunday = startDate.getDay() === 0
    if (!saturday && !sunday) {
      numWorkDays++
    }
    startDate = addDays(startDate, 1)
  }
  return numWorkDays
}

export async function fetchCurrentTime(zone = 'Australia/Sydney') {
  return axios
    .get(`https://worldtimeapi.org/api/timezone/${zone}`)
    .then((response) => {
      return new Date(response.data.datetime) || new Date()
    })
    .catch((e) => {
      console.error('Error fetching data from worldtimeapi')
      console.error(e)
      return new Date()
    })
}

export function isPastDate(_date) {
  if (!_date) return false
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  const date = new Date(_date.toISOString())
  date.setHours(0, 0, 0, 0)

  return date < now
}
